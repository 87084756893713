
import { defineComponent } from 'vue'
import PageContent from '@/components/layout/pageContent/PageContent.vue'

export default defineComponent({
  components: {
    PageContent,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
  },
})
